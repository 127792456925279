import React from 'react'
import { useLoaderData } from 'react-router'
import axios from 'axios'

import { App as SendbirdApp } from '@sendbird/uikit-react';
import '@sendbird/uikit-react/dist/index.css';
import useTheme from '@hooks/useTheme';

async function loader() {
    const { data } = await axios.get('/backoffice/inbox-sendbird')

    return data
}

function Element() {
    const loaderData = useLoaderData()
    const [theme] = useTheme()
    return <div className="h-[82vh]">
        <SendbirdApp
            appId={loaderData.appId}
            userId={loaderData.userId}
            nickname={loaderData.nickname}
            profileUrl={loaderData.profileUrl}
            replyType='THREAD'
            theme={theme === 'dark' ? 'dark' : 'light'}
        />
    </div>
}

export const InboxSendbird = {
    Element,
    loader,
}